import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { useAuthContext } from "../contexts/AuthContext"

export default function Signin() {

  const navigate = useNavigate()
  const {jacobtest,firebasestateuser, signin } = useAuthContext()

  const emailRef = useRef()
  const passwordRef = useRef()

  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  
  async function handleSubmit(e) {
    e.preventDefault()
    try {
      setError("")
      setLoading(true)
      await signin( emailRef.current.value, passwordRef.current.value )
      navigate("/dashboard", { replace:true })
    } catch(err) {
      if ( err.code === 'auth/user-not-found')     {setError('User not found')} 
      else if ( err.code === 'auth/wrong-password'){setError('Auth/Password invalid')}
      else { setError( 'Failed to sign in' )}
    }
    setLoading(false)
  }

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Sign In</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          { firebasestateuser && firebasestateuser.email }
          <Form onSubmit={handleSubmit} className="mb-2">
            <Form.Group id="email" className="mb-2">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password" className="mb-4">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              Sign In
            </Button>
          </Form>
          <div className="w-100 text-center mt-3">
            <Link to="/forgotpassword">Forgot Password?</Link>
          </div>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        Need an account? <Link to="/signup">Sign Up</Link>
      </div>
    </>
  )
}