import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Card, Button, Alert } from "react-bootstrap"
import { useAuthContext } from "../contexts/AuthContext"

export default function Dashboard() {
  const navigate = useNavigate()
  const { jacobtest, firebasestateuser, signout } = useAuthContext()
  const [error, setError] = useState("")

  async function handleLogout() {
    setError("")
    try {
      await signout()
      navigate("/", { replace: true })
    } catch {
      setError("Failed to log out")
    }
  }

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Profile ... ( open )</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          {jacobtest && jacobtest.name }
          {firebasestateuser && firebasestateuser.email }
          <Link to="/updateprofile" className="btn btn-primary w-100 mt-3">
            Update Profile
          </Link>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Button variant="link" onClick={handleLogout}>
          Sign Out
        </Button>
      </div>
    </>
  )
}