import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Container } from "react-bootstrap"

import { AuthContextProvider } from "./contexts/AuthContext"

import HomePage from "./components/HomePage";
import NotFoundPage from "./components/NotFoundPage";

import Signin from "./components/Signin";
import Signup from "./components/Signup";
import Dashboard from "./components/Dashboard";
import ForgotPassword from "./components/ForgotPassword";
import UpdateProfile from "./components/UpdateProfile";
import Protected from "./components/ProtectedRoute";

function App() {
  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: "400px" }}>
      <AuthContextProvider>
      <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<HomePage />}/>
            <Route path="signin/*" element={<Signin />}/>
            <Route path="signup/*" element={<Signup />}/>
            <Route path="/forgotpassword" element={<ForgotPassword />}/>
            <Route 
              path="dashboard/*" 
              element={ <Protected>
                          <Dashboard />
                        </Protected>
                      }
            />
             <Route 
              path="updateprofile/" 
              element={ <Protected>
                          <UpdateProfile />
                        </Protected>
                      }
            />
             <Route path="*" element={<NotFoundPage />}/>
          </Routes>
          </BrowserRouter>  
      </AuthContextProvider>
    
      </div>    
    </Container>
  
  );
}

export default App;
