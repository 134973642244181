import React from "react"

const NotFoundPage = () => {
    return ( 
        <div style={{ textAlign: 'center'}}>
            <h4>AudreyRunNotFoundPage</h4>
        </div> 
    )
}
 
export default NotFoundPage;