import React from "react"
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import logo from "../images/Artwork.png"
const HomePage = () => {
    return ( 
    <div>
        <div style={{ textAlign: 'center', marginBottom: '20px'}}>
            <img src = { logo } alt="logo"></img>
        </div>
        <div className="w-10 text-center mt-2">
        <p>... UNDER CONSTRUCTION ...</p>
            <Link to="/signin">Registered</Link>
            <Link  style={{ marginLeft: '10px' }} to="/signup">New Visitor</Link>
        </div>
    </div> 
    )
}
export default HomePage;