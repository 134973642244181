import { createUserWithEmailAndPassword, 
    signOut, 
    onAuthStateChanged, 
    signInWithEmailAndPassword, 
    sendPasswordResetEmail,
    updatePassword,
    updateEmail
} from "firebase/auth";
import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase"
//create a context which is used for Authentication
const AuthContext = React.createContext()

//export a funtion useAuthContext which can be used by all children component by applying useContext hook
//instead, one can do this in each children, ie import the AuthContext then apply by using useContext(AuthContext)
export const useAuthContext = () => {
    return useContext( AuthContext )
}

//establish the Context Provider and pass down value. Where value is an object
//Apply Provider in the root/top components so it affect ALL underneath components. In this case put Provider in App.js

export function AuthContextProvider({ children }) {

    const [ jacobtest, setJacobTest ]  = useState({"name":"currentuser"})
    const [ firebasestateuser, setFirebaseStateUser ] = useState({})

    function signup( email, password ){
        return createUserWithEmailAndPassword( auth, email, password )
    }
    function signin( email, password ){
        return signInWithEmailAndPassword( auth, email, password )
    }
    function signout(){
        return signOut(auth)
    }
    function resetpassword( email ){
        return sendPasswordResetEmail(auth, email)
    }

    function updateemail(email) {
        return updateEmail(firebasestateuser,  email)
    }
  
    function updatepassword( password ) {
        return updatePassword( firebasestateuser,  password )
    }


    useEffect(() => {
        const unbs = onAuthStateChanged( auth, datafromfirebase => setFirebaseStateUser(datafromfirebase) )
        return unbs
    })

    return ( 
        <AuthContext.Provider value={ {jacobtest, signup, signin, signout, resetpassword, updateemail, updatepassword,firebasestateuser } }>
            { children }
        </AuthContext.Provider>
     );
}
